import React, { useState } from 'react';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';
import Modal from 'react-modal';
import { Button, CancelButton } from 'components/awesome_button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import expireIcon from 'assets/expired.png'
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from "js-cookie";

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="down" ref={ref} {...props} />
));

const InactivityPopup = ({ isOpen, onClose, userLogout, reloadPage }) => (
    <Dialog open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <div className="session">
            <div className="icon">
                <img src={expireIcon} alt="Session Expired Icon" />
            </div>
            <h2>Your session has expired</h2>
            <p>Please refresh the page. Don't worry, we kept all of your filters and data in place.</p>
            <DialogActions>
                <CancelButton
                    onClick={() =>
                        userLogout({ returnTo: `${window.location.origin}/login` })
                    }
                    title="Log Out"
                    style={{
                        marginRight: '0.5rem'
                    }}
                />
                <Button className="refresh-button" onClick={() => reloadPage()}>Refresh Page</Button>
            </DialogActions>
        </div>
    </Dialog>
);

const SessionModal = () => {
    const [isIdle, setIsIdle] = useState(false);
    const { logout } = useAuth0();
    const handleOnIdle = () => {
        if (Cookies.get("token")) {
            setIsIdle(true);
        }
    };

    const handleClosePopup = () => {
        setIsIdle(false);
    };
    const userLogout = (url) => {
        sessionStorage.clear();
        localStorage.clear();
        Cookies.remove("token");
        localStorage.setItem("tour", 1);
        logout(url);
        setIsIdle(false);
    };
    const reloadPage = () => {
        Cookies.remove("token");
        localStorage.removeItem("userProfile");
        localStorage.removeItem("selectedGroup");
        localStorage.removeItem("AssetGroupParams");
        window.location.reload()
        setIsIdle(false);
    };

    useIdleTimer({
        timeout: 1000 * 60 * 59, // 59 minutes
        onIdle: handleOnIdle,
        debounce: 500
    });

    return (
        <IdleTimerProvider>
            <InactivityPopup isOpen={isIdle} onClose={handleClosePopup} userLogout={userLogout} reloadPage={reloadPage} />
        </IdleTimerProvider>
    );
};

export default SessionModal;