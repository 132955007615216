import map from "lodash/map";
import { checkSeverity } from "utils/severity_check";

import filter from "lodash/filter";
import uniq from "lodash/uniq";
import flattenDeep from "lodash/flattenDeep";
import { API, APIv2, fileAPI } from "utils/api";
import { showSuccessModal } from "actions/modals";
import { errorHandler } from "utils/error_handler";
import {
  fetchScanDetails,
  fetchAllScanAssets,
  fetchAllScanAssetExposure,
  fetchAllScanIssues,
  fetchAllScanDataleaks
} from "actions/scan_details";
import moment from "moment-timezone";
import shortid from "shortid";
import updateUrlAndFetchData from "utils/update_data_url";
import axios from "axios";

export const updateNotificationId = (id, date) => (dispatch) => {
  dispatch({
    type: "SET_NOTIFICATION_ID",
    id,
    date,
  });
};

export const getOrgsAllAssetTypes = () => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DROPDOWN_ASSETS_REQUEST" });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ group_name: "asset_group", value: "" }];
    const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
    const { data } = await API.get(`/orgs/asset-types/${filterUrl}`);
    dispatch({
      type: "FETCH_ORGS_ASSET_UNIQUE_TYPES",
      data: data.org_asset_types,
      tags: uniq(flattenDeep(data.tags)),
      ports: data.ports.filter((item) => item !== null),
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getAllAssetTypesWeAccept = () => async (dispatch) => {
  try {
    const { data } = await API.get(`/asset-types/`);
    dispatch({
      type: "FETCH_ALL_ASSET_UNIQUE_TYPES",
      data: data.asset_types,
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};
export const updateSavedAssetsReduxState = (updatedAssetIds, action) => (
  dispatch,
  getState
) => {
  dispatch({ type: "FETCH_ASSET_REQUEST" });

  const assets = map(getState().assetTable.savedData, (asset) => ({
    ...asset,
  }));
  let assetsUpdated = [];

  assetsUpdated = map(assets, (asset) => {
    if (updatedAssetIds.includes(asset.id)) {
      asset.status = action;
    }
    return asset;
  });
  dispatch({
    type: "UPDATE_SAVED_ASSETS",
    assets: assetsUpdated,
  });
};

export const updateSavedAssetsReduxStateWithTags = (updatedAssetIds, tags) => (
  dispatch,
  getState
) => {
  const assets = map(getState().assetTable.savedData, (asset) => ({
    ...asset,
  }));
  let assetsUpdated = [];

  assetsUpdated = map(assets, (asset) => {
    if (updatedAssetIds.includes(asset.id)) {
      asset.tags = uniq([...asset.tags, ...tags]);
    }
    return asset;
  });

  dispatch({
    type: "UPDATE_SAVED_ASSETS",
    assets: assetsUpdated,
  });
};

export const updateSavedAssetsReduxStateAfterRemoveTags = (
  updatedAssetIds,
  tagToRemove
) => (dispatch, getState) => {
  const assets = map(getState().assetTable.savedData, (asset) => ({
    ...asset,
  }));
  let assetsUpdated = [];

  assetsUpdated = map(assets, (asset) => {
    if (updatedAssetIds.includes(asset.id)) {
      asset.tags = filter(asset.tags, (tag) => tag !== tagToRemove);
    }
    return asset;
  });

  dispatch({
    type: "UPDATE_SAVED_ASSETS",
    assets: assetsUpdated,
  });
};

export const fetchAssetInventoryTableData = (filterUrl, history) => async (
  dispatch
) => {
  try {
    const id = shortid.generate();
    dispatch({ type: "FETCH_ASSET_REQUEST", id: id });

    const baseURL = `/orgs/assets/`;
    const { data } = await API.get(`${baseURL}${filterUrl}`);

    const newAssets = map(data.results, (asset) => ({
      ...asset,
      key: `${asset.asset}#${asset.id}`,
      id: asset.id,
      asset: asset.asset,
      tags: asset.tags || [],
      techs: asset.techs || [],
      country_code: "",
      cloud_host: [],
      screenshots: asset.screenshots || [],
      risk_tags: asset.risk_tags || [],
      vulnerability_count: asset.vulnerability_count,
      severity: checkSeverity(asset.severity),
      asset_type: asset.asset_type,
      ip_address: asset.ip_address || [],
      is_obsolete: asset.is_obsolete || false,
      is_new: asset.is_new || false,
      asset_id: asset.asset_expo_id || null,
      asset_expo_is_new: asset.asset_expo_is_new || false,
      asset_expo_is_obsolete: asset.asset_expo_is_obsolete || false,
      country_name: asset.country_name,
      inventories: asset.inventories || {},
      extra_info: asset.extra_info || {},
      headers: asset.headers || {},
      status: asset.status,
      port: asset.port,
      favicon_url: asset.favicon_url,
      ports: asset.port,
      first_seen: moment.utc(asset.created).format("ll"),
      last_seen: asset.last_seen
        ? moment.utc(asset.last_seen).format("ll")
        : "No Last Seen Date Found",
    }));

    dispatch({
      type: "FETCH_ASSET_SUCCESS",
      id: id,
      assets: newAssets,
      nextItems: data.next,
      previousItems: data.previous,
      assetsCount: data.count,
      currentUrl: filterUrl,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNotificationsItems = (filterUrl, history, id) => async (
  dispatch
) => {
  try {
    dispatch({ type: "NOTIFICATIONS_RECORDS_REQUEST" });
    const baseURL = `/notifications/`;
    const response = await APIv2.get(`${baseURL}${id}/${filterUrl}`);
    if (response.status === 200) {
      dispatch({
        type: "NOTIFICATIONS_RECORDS_SUCCESS",
        data: response.data,
        nextItems: response.data.next,
        previousItems: response.data.previous,
        meta: response.data._meta,
      });
      dispatch({
        type: "FETCH_ALL_UNIQUE_DATALEAK_SIGNATURES",
        data: response.data._meta,
      });
      dispatch({
        type: "FETCH_ORGS_ASSET_UNIQUE_TYPES",
        data: response.data._meta.asset_types,
      });
      dispatch({
        type: "FETCH_ALL_ISSUE_UNIQUE_TYPES",
        data: response.data._meta.titles,
        assets: response.data._meta.assets.filter((el) => el !== null) || [],
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextNotificationsItems = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: "NOTIFICATIONS_RECORDS_REQUEST" });

    const response = await APIv2.get(nextUrl.replace('http', 'https'));
    if (response.status === 200) {
      dispatch({
        type: "NOTIFICATIONS_RECORDS_SUCCESS",
        data: response.data,
        nextItems: response.data.next,
        previousItems: response.data.previous,
        meta: response.data._meta,
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextSavedAssets = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const { data } = await API.get(nextUrl);

    const newAssets = map(data.results, (asset) => ({
      ...asset,
      key: `${asset.asset}#${asset.id}`,
      id: asset.id,
      asset: asset.asset,
      tags: asset.tags || [],
      techs: asset.techs || [],
      country_code: "",
      cloud_host: [],
      screenshots: asset.screenshots || [],
      risk_tags: asset.risk_tags || [],
      vulnerability_count: asset.vulnerability_count,
      severity: checkSeverity(asset.severity),
      asset_type: asset.asset_type,
      ip_address: asset.ip_address || [],
      is_obsolete: asset.is_obsolete || false,
      is_new: asset.is_new || false,
      asset_id: asset.asset_expo_id || null,
      asset_expo_is_new: asset.asset_expo_is_new || false,
      asset_expo_is_obsolete: asset.asset_expo_is_obsolete || false,
      country_name: asset.country_name,
      inventories: asset.inventories || {},
      headers: asset.headers || {},
      extra_info: asset.extra_info || {},
      status: asset.status,
      ports: asset.port,
      favicon_url: asset.favicon_url,
      port: asset.port,
      first_seen: moment.utc(asset.created).format("ll"),
      last_seen: asset.last_seen
        ? moment.utc(asset.last_seen).format("ll")
        : "No Last Seen Date Found",
    }));

    dispatch({
      type: "FETCH_ASSET_SUCCESS",
      assets: newAssets,
      assetsCount: data.count,
      nextItems: data.next,
      previousItems: data.previous,
      currentUrl: nextUrl ? `?${nextUrl.split("?")[1]}` : "",
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const addToSavedAssets = (assetsToAdd, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: "NEW_ASSET_ADD_REQUEST" });

    const dataObject = { assets: assetsToAdd };
    const response = await API.post("/orgs/assets/", dataObject);
    if (response.status === 201) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "New asset is added to the list successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch(fetchAssetInventoryTableData(currentUrl, history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addToSeed = (assetsToAdd, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: "NEW_ASSET_ADD_REQUEST" });

    const dataObject = { ids: assetsToAdd };
    const response = await API.post("/orgs/add-to-seed/", dataObject);
    if (response.status === 201) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: response.data.message,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch(fetchUniqueAttackSurfaceAssets(currentUrl, history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteSelectedAssets = (history, assetsToDelete) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const response = await API.delete("/orgs/assets/", {
      data: {
        ids: assetsToDelete,
      },
    });

    if (response.status === 200) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch(fetchAssetInventoryTableData(currentUrl, history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteSelectedAssetsInventory = (
  history,
  assetsData
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const response = await API.delete(
      `org/${assetsData.owner_org}/asset-inventory/`,
      {
        data: {
          ids: [assetsData.id],
        },
      }
    );

    if (response.status === 200) {
      const baseUrl = `/admin/orgs/${assetsData.owner_org}/scans/asset-inventory`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchAllScanAssets,
        history,
        assetsData.owner_org
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteSelectedAssetsExposure = (
  history,
  assetsData,
  orgId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const response = await API.delete(`org/${orgId}/asset-exposure/`, {
      data: {
        ids: assetsData,
      },
    });

    if (response.status === 200) {
      const baseUrl = `/admin/orgs/${orgId}/scans/asset-exposure`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchAllScanAssetExposure,
        history,
        orgId
      );
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: response.data.message,
          data: response.data.fields || [],
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteAssetConfirmation = (
  history,
  exposureAssetId,
  inventoryAssetId
) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const response = await API.post(`assets/vi/`, {
      ae_id: exposureAssetId,
      ai_id: inventoryAssetId,
    });

    if (response.status === 200) {
      dispatch({
        type: "SHOW_RELATED_ISSUES",
        issues: response.data.count,
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteSelectedAssetsIssue = (
  history,
  assetsData,
  orgId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const response = await API.delete(`org/${orgId}/issues/`, {
      data: {
        ids: assetsData,
      },
    });

    if (response.status === 200) {
      const baseUrl = `/admin/orgs/${orgId}/scans/issues`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchAllScanIssues,
        history,
        orgId
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const handleActiveSelectedAssetsIssue = (
  history,
  assetsData,
  action,
  orgId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_SCAN_DETAILS_ISSUES_REQUEST" });

    const response = await API.put(`org/${orgId}/issues/active/`, {
      action: action,
      ids: assetsData,
    });

    if (response.status === 200) {
      let massage =
        action === "active"
          ? "Selected items activated successfully"
          : "Selected items inactivated successfully";
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: response.data.message ? response.data.message : massage,
          data: response.data.fields || [],
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      const baseUrl = `/admin/orgs/${orgId}/scans/issues`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchAllScanIssues,
        history,
        orgId
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const handleActiveSelectedAssetsDataleak = (
  history,
  assetsData,
  action,
  orgId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_SCAN_DETAILS_DATA_LEAKS_REQUEST" });

    let params = {
      action: action,
      ids: assetsData,
    };
    const response = await API.put(`org/${orgId}/dataleaks/active/`, params);

    if (response.status === 200) {
      let massage =
        action === "active"
          ? "Selected items activated successfully"
          : "Selected items inactivated successfully";
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: response.data.message ? response.data.message : massage,
          data: response.data.fields || [],
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      const baseUrl = `/admin/orgs/${orgId}/scans/dataleaks`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchAllScanDataleaks,
        history,
        orgId
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteSelectedAssetsDataleak = (
  history,
  assetsData,
  orgId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const response = await API.delete(`org/${orgId}/dataleaks/`, {
      data: {
        ids: assetsData,
      },
    });

    if (response.status === 200) {
      const baseUrl = `/admin/orgs/${orgId}/scans/dataleaks`;
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchAllScanDataleaks,
        history,
        orgId
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleUpdateTagsSelected = (
  tags,
  assetsToUpdate,
  selectAll,
  filters,
  history
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const response = await APIv2.put("/tags/", {
      id: assetsToUpdate,
      tags,
      selected_all: selectAll,
      filters,
    });

    if (response.status === 200) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch(fetchAssetInventoryTableData(currentUrl, history));
      dispatch(getOrgsAllAssetTypes(history));
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: response.data.message === 'Tags already assigned' ? 'Warning!' : "Success!",
          message: response.data.message,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteTagsSelected = (
  tags,
  assetsToUpdate,
  selectAll,
  filters,
  history
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });

    const response = await APIv2.delete("/tags/", {
      data: {
        id: assetsToUpdate,
        tags,
        selected_all: selectAll,
        filters,
      },
    });

    if (response.status === 200) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch(fetchAssetInventoryTableData(currentUrl, history));
      dispatch(getOrgsAllAssetTypes(history));
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "Tag Deleted successfully",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleAddingAssetsRhAdmin = (
  data,
  jobId,
  dataType,
  history
) => async (dispatch) => {
  try {
    dispatch({
      type: "SHOW_MODAL_LOADER",
    });

    const response = await API.post(`/scans/${jobId}/${dataType}`, data);

    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `The ${dataType} added successfully.`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch(fetchScanDetails(jobId, history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const downloadOrganisationAssets = (history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: "DOWNLOAD_SCAN_REPORT" });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    };
    const { data } = await fileAPI.get(`/orgs/assets/downloads/`, config);

    let blob = new Blob([data]);

    const orgName = getState().authentication.user.organisation;
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `Assets_${orgName}.csv`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const uploadNewAssetCSV = (file, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: "NEW_ASSET_ADD_REQUEST" });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const response = await fileAPI.post("/orgs/assets/uploads/", file, config);

    if (response.status === 201) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch(fetchAssetInventoryTableData(currentUrl, history));
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "New asset is added to the list successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchAssetsOnSearch = (searchText, scanId) => async (dispatch) => {
  dispatch({ type: "FETCH_ASSET_LIST_ON_SEARCH_REQUEST" });

  const { data } = await API.get(
    `/scans/${scanId}/assets?search=${searchText}`
  );
  const assetList = map(data.results, (item) => item.asset);
  dispatch({
    type: "FETCH_ASSET_LIST_ON_SEARCH_SUCCESS",
    assets: assetList,
  });
};

export const fetchUniqueAttackSurfaceAssets = (filterUrl, history) => async (
  dispatch
) => {
  try {
    const id = shortid.generate();
    dispatch({ type: "FILTER_UNIQUE_ASSET_REQUEST", id: id });

    const baseUrl = "/orgs/assets/unique/";
    const { data } = await API.get(`${baseUrl}${filterUrl}`);

    if (data) {
      const newAssets = map(data.results, (asset, i) => ({
        ...asset,
        key: shortid.generate(),
        asset: asset.asset,
        id: asset.id,
        tags: asset.tags || [],
        techs: asset.techs || [],
        url: asset.url
          ? asset.url.startsWith("http")
            ? asset.url
            : `http://${asset.url}`
          : asset.url,
        ip_address: asset.ip_address || [],
        country_code: asset.country_code,
        country_name: asset.country_name,
        screenshots: asset.screenshots || [],
        cloud_host: asset.cloud_host || [],
        is_moved_to_targets: asset.is_moved_to_targets || null,
        inventories: asset.inventories || {},
        risk_tags: asset.risk_tags || [],
        severity: checkSeverity(asset.severity),
        asset_type: asset.asset_type,
        extra_info: asset.extra_info || {},
        headers: asset.headers || {},
        showMetaHeaders: asset.show_meta_headers,
        first_seen: moment.utc(asset.created).format("ll"),
        last_seen: asset.last_seen
          ? moment.utc(asset.last_seen).format("ll")
          : "No Last Seen Date Found",
      }));
      dispatch({
        type: "FETCH_ASSET_SUCCESS",
        id: id,
        assets: newAssets,
        assetsCount: data.count,
        nextItems: data.next,
        previousItems: data.previous,
        currentUrl: filterUrl,
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextUniqueAttackSurfaceAssets = (history, nextUrl) => async (
  dispatch,
  getState
) => {
  try {
    const id = shortid.generate();
    dispatch({ type: "FILTER_UNIQUE_ASSET_REQUEST", id: id });
    // const nextUrl = getState().assetTable.nextItems;
    const { data } = await API.get(nextUrl);

    const newAssets = map(data.results, (asset) => ({
      ...asset,
      key: shortid.generate(),
      asset: asset.asset,
      id: asset.id,
      tags: asset.tags || [],
      techs: asset.techs || [],
      url: asset.url
        ? asset.url.startsWith("http")
          ? asset.url
          : `http://${asset.url}`
        : asset.url,
      ip_address: asset.ip_address || [],
      country_code: asset.country_code,
      country_name: asset.country_name,
      screenshots: asset.screenshots || [],
      cloud_host: asset.cloud_host || [],
      is_moved_to_targets: asset.is_moved_to_targets || null,
      inventories: asset.inventories || {},
      extra_info: asset.extra_info || {},
      headers: asset.headers || {},
      showMetaHeaders: asset.show_meta_headers,
      risk_tags: asset.risk_tags || [],
      severity: checkSeverity(asset.severity),
      asset_type: asset.asset_type,
      first_seen: moment.utc(asset.created).format("ll"),
      last_seen: asset.last_seen
        ? moment.utc(asset.last_seen).format("ll")
        : "No Last Seen Date Found",
    }));

    // const assetsData = getState().assetTable.data;
    // const assetsDataUpdated = assetsData.concat(newAssets);

    dispatch({
      type: "FETCH_ASSET_SUCCESS",
      id: id,
      assets: newAssets,
      assetsCount: data.count,
      nextItems: data.next,
      previousItems: data.previous,
      currentUrl: nextUrl ? `?${nextUrl.split("?")[1]}` : "",
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleAttackSurfaceCardNumber = () => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_CARD_NUMBER_REQUEST" });

    const { data } = await API.get("/orgs/assets/header/");

    dispatch({
      type: "FETCH_ASSET_CARD_NUMBER_SUCCESS",
      data,
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getAssetExposuresTopCardNumber = (attachedUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: "FETCH_ASSET_EXPOSURES_CARD_NUMBER_REQUEST" });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }];
    const filterUrl = attachedUrl && attachedUrl !== '?' ? `${attachedUrl}&asset_group=${assetGroupParams[0].value}` : `?asset_group=${assetGroupParams[0].value}`
    const { data } = await API.get(
      `/orgs/assets/overview/${filterUrl}`
    );
    let assetTypes = map(data.asset_types, (asset) => ({
      key: shortid.generate(),
      asset_type: asset.asset_type,
      count: asset.type_count,
    }));
    let tagTypes = map(data.tags, (asset) => ({
      key: shortid.generate(),
      tag: asset.tag,
      count: asset.type_count,
    }));

    dispatch({
      type: "FETCH_ASSET_EXPOSURES_CARD_NUMBER_SUCCESS",
      data: data.overview,
      assetTypes,
      tagTypes,
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const fetchAllPossibleAssetExposure = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_POSSIBLE_ASSET_REQUEST" });
    // const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
    //   ? JSON.parse(localStorage.getItem('AssetGroupParams'))
    //   : [{ name: "Default Group", value: '' }];
    const baseUrl = `/orgs/third-party-assets/${filterUrl}`;
    const { data } = await API.get(`${baseUrl}`);
    dispatch({
      type: "FETCH_POSSIBLE_ASSET_SUCCESS",
      assetsData: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchAllPossibleAssetTypes = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/orgs/third-party-assets-types/`);
    dispatch({
      type: "FETCH_THIRD_PARTY_ASSET_TYPE_SUCCESS",
      data
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextPossibleAssetExposure = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_POSSIBLE_ASSET_REQUEST" });
    // const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
    //   ? JSON.parse(localStorage.getItem('AssetGroupParams'))
    //   : [{ name: "Default Group", value: '' }];
    // const baseUrl = `/orgs/third-party-assets/?asset_group=${assetGroupParams[0].value}`;
    const { data } = await API.get(`${nextUrl}`);
    dispatch({
      type: "FETCH_POSSIBLE_ASSET_SUCCESS",
      assetsData: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

const convertCveData = (inputData) => {
  const result = [];

  for (const tech in inputData.cve_list) {
    const cveData = inputData.cve_list[tech];
    // const cveInfo = cveData[cveId];
    const techData = {
      tech,
      // cve_published_date: cveData.cve_published_date,
      base_score: cveData.base_score ? cveData.base_score > 0 && cveData.base_score <= 2 ? 1 : cveData.base_score <= 4 ? 2 : cveData.base_score <= 6 ? 3 : cveData.base_score > 6 ? 4 : 0 : 0,
      asset_type_icon_url: cveData.asset_type_icon_url,
      version: cveData.version,
      // cve_last_modified_date: cveData.cve_last_modified_date,
      cve_list: cveData.cves_modified_in_last_30_days,
      cves_published: cveData.cves_published_in_last_30_days,
    };
    result.push(techData);
    // for (const cveId in cveData) {
    // }
  }

  return result;
}
export const fetchTechnologies = (history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_TECHNOLOGIES_REQUEST" });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }];
    const baseUrl = `/cves/?asset_group=${assetGroupParams[0].value}`;
    const { data } = await API.get(`${baseUrl}`);
    dispatch({
      type: "FETCH_TECHNOLOGIES_SUCCESS",
      techsData: convertCveData(data)
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchTechnologiesList = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_TECHNOLOGIES_REQUEST" });
    // const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
    //   ? JSON.parse(localStorage.getItem('AssetGroupParams'))
    //   : [{ name: "Default Group", value: '' }];
    // const baseUrl = `/techs/?asset_group=${assetGroupParams[0].value}`;
    const { data } = await API.get(`/techs/${filterUrl}`);
    dispatch({
      type: "FETCH_TECHNOLOGIES_LIST_SUCCESS",
      data: data.techs
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const filterAICsv = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_REQUEST" });
    const baseURL = `/orgs/assets/`;
    const response = await API.get(
      `${baseURL}${filterUrl ? filterUrl + "&export=true" : "?export=true"}`
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "asset_inventory.csv");
    document.body.appendChild(link);
    link.click();
    dispatch({ type: "FILTER_CSV_SUCCESS" });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

const convertAssetData = (groups, data) => {
  // const convertedData = data.reduce((result, item) => {
  //   const existingGroup = groups.find(group => group.asset_group === item.asset_group);

  //   if (existingGroup) {
  //     const existingTarget = existingGroup.target.find(target => target.target_type === item.target_type);

  //     if (existingTarget) {
  //       existingTarget.target_name.push({
  //         map_id: item.id,
  //         id: item.target,
  //         target: item.target,
  //         target_name: item.target_name,
  //       });
  //     } else {
  //       existingGroup.target.push({
  //         target_name: [
  //           {
  //             map_id: item.id,
  //             id: item.target,
  //             target: item.target,
  //             target_name: item.target_name,
  //           },
  //         ],
  //         edit: true,
  //         target_type: item.target_type,
  //       });
  //     }
  //   } else {
  //     groups.push({
  //       asset_group: item.asset_group,
  //       id: item.id,
  //       group_name: item.group_name,
  //       target: [
  //         {
  //           target_name: [
  //             {
  //               map_id: item.id,
  //               id: item.target,
  //               target: item.target,
  //               target_name: item.target_name,
  //             },
  //           ],
  //           edit: true,
  //           target_type: item.target_type,
  //         },
  //       ],
  //     });
  //   }

  //   return groups;
  // }, []);
  const result = groups.map((item1) => {
    const list = []
    const matchingItems = data
      .filter((item2) => item2.asset_group === item1.id)
      .map((filteredItem) => ({
        map_id: filteredItem.id,
        id: filteredItem.target,
        target: filteredItem.target,
        target_type: filteredItem.target_type,
        target_name: filteredItem.target_name,
      }));
    const typeList = [...new Set(data
      .filter((item2) => item2.asset_group === item1.id)
      .map((filteredItem) => (filteredItem.target_type)))]
    typeList.forEach(el => {
      list.push({
        target_name: matchingItems.filter(i => i.target_type === el),
        edit: true,
        target_type: el

      })

    })

    return {
      asset_group: item1.id,
      id: item1.id,
      group_name: item1.group_name,
      target: list,
    };
  });
  return result
}

export const fetchAssetGroupList = (history) => async (dispatch) => {
  dispatch({ type: "FETCH_ASSET_GROUP_REQUEST" });
  try {
    API.get(`/orgs/asset-group/group/`).then((res) => {
      API.get(`/orgs/asset-group/target-group-map/`).then((response) => {
        const newData = convertAssetData(res.data.results.sort((a, b) => {
          if (a.group_name === "default") return -1;
          if (b.group_name === "default") return 1;
          return 0;
        }), response.data.results);
        setTimeout(() => {
          dispatch({
            type: "FETCH_ASSET_GROUP_SUCCESS",
            data: newData,
            assetsCount: response.data.count,
            nextItems: response.data.next,
            previousItems: response.data.previous
          });
        }, 1000);
      })
    })
    // const { res } = await API.get(`/orgs/asset-group/group/`);
    // const baseUrl = `/orgs/asset-group/target-group-map/`;
    // const { data } = await API.get(`${baseUrl}`);
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchAllAssetGroupList = (history) => async (dispatch) => {
  try {

    const baseUrl = `/orgs/asset-group/group/`;
    const { data } = await API.get(`${baseUrl}`);
    dispatch({
      type: "FETCH_ALL_ASSET_GROUP_SUCCESS",
      data: data.results,
      // assetsCount: data.count,
      // nextItems: data.next,
      // previousItems: data.previous
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchUserAssetGroupList = (history, role) => async (dispatch) => {
  try {

    const baseUrl = `/user/get-user-asset-group/`;
    const { data } = await API.get(`${baseUrl}`);
    // const role = localStorage.getItem('role');
    dispatch({
      type: "FETCH_ALL_USER_ASSET_GROUP_LIST_SUCCESS",
      data: data.map(el => {
        return {
          id: el.asset_group_id,
          group_name: el.asset_group
        }
      }),
      // assetsCount: data.count,
      // nextItems: data.next,
      // previousItems: data.previous
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchAllUserAssetGroupList = (history) => async (dispatch) => {
  try {

    const baseUrl = `user/get-all-user-asset-group-list/`;
    const { data } = await API.get(`${baseUrl}`);
    dispatch({
      type: "FETCH_ALL_USER_ASSET_GROUP_SUCCESS",
      data: data,
      // assetsCount: data.count,
      // nextItems: data.next,
      // previousItems: data.previous
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextAssetGroup = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });
    API.get(`/orgs/asset-group/group/`).then((res) => {
      API.get(nextUrl).then((response) => {
        const newData = convertAssetData(res.data.results, response.data.results);
        setTimeout(() => {
          dispatch({
            type: "FETCH_ASSET_GROUP_SUCCESS",
            data: newData,
            assetsCount: response.data.count,
            nextItems: response.data.next,
            previousItems: response.data.previous
          });
        }, 1000);
      })
    })


  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchTargetList = (history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const baseUrl = `/orgs/asset-group/target/`;
    const { data } = await API.get(`${baseUrl}`);
    dispatch({
      type: "FETCH_ASSET_TARGET_SUCCESS",
      data: data,

    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const addNewGroup = (params, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const response = await API.post(`/orgs/asset-group/group/`, {
      group_name: params.group_name
    });
    params.target.forEach((el) => {
      el.target_name.forEach(e => {
        dispatch(addGroupTargetMapping(e, response.data.id, history))
      })
    })
    // if (response.status === 201) {
    // }
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message: "Asset group is added successfully.",
        redirectTo: "",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    setTimeout(() => {
      dispatch(fetchAssetGroupList())
    }, 1000);
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addNewUserGroupMap = (params, history) => async (dispatch) => {
  try {
    // dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const response = await API.post(`/user/asset-group-map/`, params);
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message: "Asset group is mapped successfully.",
        redirectTo: "",
        buttonTitle: "Ok",
      },
    });
    dispatch(fetchAllUserAssetGroupList())
    dispatch(showSuccessModal());
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteUserGroupMap = (params, history) => async (dispatch) => {
  try {
    // dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const response = await API.delete(`/user/asset-group-map/`, {
      data: {
        id: params
      }
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateNewGroup = (params, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const response = await API.put(`/orgs/asset-group/group/`, {
      id: params.asset_group,
      group_name: params.group_name
    });
    if (response.status === 200 || response.status === 201) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "Asset group is updated successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());


      dispatch(fetchAssetGroupList())
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addGroupTargetMapping = (params, id, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const response = await API.post(`/orgs/asset-group/target-group-map/`, {
      "target": params.id,
      "asset_group": id
    });
    if (response.status === 200) {

    }

  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const editGroupTargetMapping = (params, id, history) => async (dispatch) => {
  dispatch({ type: "FETCH_NEW_ASSET_TARGET_REQUEST" });
  return API.post(`/orgs/asset-group/target-group-map/`, {
    "target": params.id,
    "asset_group": id
  }).then((res) => {
    dispatch({ type: "FETCH_NEW_ASSET_TARGET_SUCCESS" });
    return res.data
  }).catch((error) => {
    errorHandler(error, dispatch, history);
  })
  // try {
  //   dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

  //   const response = await API.post(`/orgs/asset-group/target-group-map/`, {
  //     "target": params.id,
  //     "asset_group": id
  //   });
  //   if (response.status === 201 || response.status === 200) {
  //     return response.data
  //   }

  // } catch (error) {
  //   errorHandler(error, dispatch, history);
  // }
};
export const deleteGroup = (group, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const response = await API.delete(`/orgs/asset-group/group/`, {
      data: {
        "id": group.asset_group
      }
    });
    if (response.status === 200) {

    }
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message: "Asset group is deleted successfully.",
        redirectTo: "",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch(fetchAssetGroupList())
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteGroupTargetMapping = (id, group_id, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const response = await API.delete(`/orgs/asset-group/target-group-map/`, {
      data: {
        "id": id
      }
    });
    if (response.status === 200) {

    }

  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchAssetList = (filterUrl, history) => async (
  dispatch
) => {
  try {
    const id = shortid.generate();
    dispatch({ type: "FETCH_ASSET_REQUEST", id: id });
    const baseURL = `/orgs/http-group-keys/`;
    const { data } = await API.get(`${baseURL}${filterUrl}`);

    const newAssets = map(data.results, (asset) => ({
      ...asset,
      key: `${asset.asset}#${asset.id}`,

      first_seen: moment.utc(asset.created).format("ll"),

    }));

    dispatch({
      type: "FETCH_HTTP_ASSET_SUCCESS",
      id: id,
      assets: newAssets,
      nextItems: data.next,
      previousItems: data.previous,
      assetsCount: data.count,
      currentUrl: filterUrl,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchAssetHttpGroupList = (filterUrl, history) => async (
  dispatch
) => {
  try {
    const id = shortid.generate();
    dispatch({ type: "FETCH_ASSET_HTTP_GROUP_REQUEST", id: id });

    const baseURL = `/orgs/assets/`;
    const { data } = await API.get(`${baseURL}${filterUrl}`);

    const newAssets = map(data.results, (asset) => ({
      ...asset,
      key: `${asset.asset}#${asset.id}`,
      id: asset.id,
      asset: asset.asset,
      tags: asset.tags || [],
      techs: asset.techs || [],
      country_code: "",
      cloud_host: [],
      screenshots: asset.screenshots || [],
      risk_tags: asset.risk_tags || [],
      vulnerability_count: asset.vulnerability_count,
      severity: checkSeverity(asset.severity),
      asset_type: asset.asset_type,
      ip_address: asset.ip_address || [],
      is_obsolete: asset.is_obsolete || false,
      is_new: asset.is_new || false,
      asset_id: asset.asset_expo_id || null,
      asset_expo_is_new: asset.asset_expo_is_new || false,
      asset_expo_is_obsolete: asset.asset_expo_is_obsolete || false,
      country_name: asset.country_name,
      inventories: asset.inventories || {},
      extra_info: asset.extra_info || {},
      headers: asset.headers || {},
      status: asset.status,
      port: asset.port,
      favicon_url: asset.favicon_url,
      ports: asset.port,
      first_seen: moment.utc(asset.created).format("ll"),

    }));

    dispatch({
      type: "FETCH_ASSET_HTTP_GROUP_SUCCESS",
      id: id,
      assets: newAssets,
      nextItems: data.next,
      previousItems: data.previous,
      assetsCount: data.count,
      currentUrl: filterUrl,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};


export const fetchNextAssetHttpGroupList = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_ASSET_HTTP_GROUP_REQUEST" });

    const { data } = await API.get(nextUrl);

    const newAssets = map(data.results, (asset) => ({
      ...asset,
      key: `${asset.asset}#${asset.id}`,
      id: asset.id,
      asset: asset.asset,
      tags: asset.tags || [],
      techs: asset.techs || [],
      country_code: "",
      cloud_host: [],
      screenshots: asset.screenshots || [],
      risk_tags: asset.risk_tags || [],
      vulnerability_count: asset.vulnerability_count,
      severity: checkSeverity(asset.severity),
      asset_type: asset.asset_type,
      ip_address: asset.ip_address || [],
      is_obsolete: asset.is_obsolete || false,
      is_new: asset.is_new || false,
      asset_id: asset.asset_expo_id || null,
      asset_expo_is_new: asset.asset_expo_is_new || false,
      asset_expo_is_obsolete: asset.asset_expo_is_obsolete || false,
      country_name: asset.country_name,
      inventories: asset.inventories || {},
      headers: asset.headers || {},
      extra_info: asset.extra_info || {},
      status: asset.status,
      ports: asset.port,
      favicon_url: asset.favicon_url,
      port: asset.port,
      first_seen: moment.utc(asset.created).format("ll"),
      last_seen: asset.last_seen
        ? moment.utc(asset.last_seen).format("ll")
        : "No Last Seen Date Found",
    }));

    dispatch({
      type: "FETCH_ASSET_HTTP_GROUP_SUCCESS",
      assets: newAssets,
      assetsCount: data.count,
      nextItems: data.next,
      previousItems: data.previous,
      currentUrl: nextUrl ? `?${nextUrl.split("?")[1]}` : "",
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextAssetList = (history, nextUrl) => async (dispatch) => {
  try {
    const id = shortid.generate();
    dispatch({ type: "FETCH_ASSET_REQUEST", id: id });
    const { data } = await API.get(nextUrl);

    const newAssets = map(data.results, (asset) => ({
      ...asset,
      key: `${asset.asset}#${asset.id}`,

      first_seen: moment.utc(asset.created).format("ll"),

    }));
    dispatch({
      type: "FETCH_HTTP_ASSET_SUCCESS",
      id: id,
      assets: newAssets,
      nextItems: data.next,
      previousItems: data.previous,
      assetsCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split("?")[1]}` : "",
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchMindMapList = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_MINDMAP_REQUEST" });
    const { data } = await API.get(`/orgs/mindmap/${filterUrl}`);

    // const newAssets = map(data.results, (asset) => ({
    //   ...asset,
    //   key: `${asset.asset}#${asset.id}`,

    //   first_seen: moment.utc(asset.created).format("ll"),

    // }));
    dispatch({
      type: "FETCH_MINDMAP_SUCCESS",
      data: data.result
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

