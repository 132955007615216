import * as authTypes from 'action_types/auth';
import { API, API_Payment } from 'utils/api';
import { errorHandler, validationErrorHandler } from 'utils/error_handler';
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import {
  showSuccessModal,
  showFailureModal,
  hideFailureModal,
  showSubscriptionWarningModal,
  hideChangePasswordModal
} from 'actions/modals';
import { resetReducerData } from 'actions/scan_details';
import { fetchTargets } from 'actions/client_settings';
import * as errorTypes from 'action_types/error';
import { downloadReport } from './dashboard';
// import { useSelector } from 'react-redux';
// import { useAuth0 } from '@auth0/auth0-react';
// import React from 'react';
// import { Redirect } from 'react-router-dom';
// import { fetchAreaChartData } from './charts';
import moment from "moment-timezone";

export const setCurrentUser = () => (dispatch) => {
  const role = localStorage.getItem('role');
  const token = Cookies.get("token");
  const user = { token, role };
  dispatch({
    type: authTypes.SET_USER,
    payload: user
  });
};

export const changePassword = (newPassword, history) => async (dispatch) => {
  try {
    // dispatch({ type: 'CHANGE_USER_PASSWORD_REQUEST' });
    const requestObject = {
      // old_password: newPassword.passwordOld,
      new_password1: newPassword.passwordNew1,
      new_password2: newPassword.passwordNew2,
      old_password: newPassword.passwordOld
    };
    const response = await API.post('/password/change/', requestObject);
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Your password has been changed successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(hideChangePasswordModal());
      dispatch(showSuccessModal());
      window.location.reload(false);
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const resetPasswordRequest = (email, history) => async (dispatch) => {
  try {
    const requestObject = {
      email
    };
    const response = await API.post('/password/reset/', requestObject);
    if (response.status === 200) {
      history.push('/login');
      toast.success(
        'If your email is registered with us, you will receive an email with instructions to change the password.',
        {
          position: toast.POSITION.TOP_CENTER
        }
      );
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const resetPassword = (newPassword, params, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: authTypes.GET_USER_PROFILE_REQUEST });

    const requestObject = {
      new_password1: newPassword.passwordNew1,
      new_password2: newPassword.passwordNew2
    };
    const response = await API.post(
      `/password/reset/confirm/${params.id}/${params.token}`,
      requestObject
    );
    if (response.status === 200) {
      history.push('/login');
      toast.success(
        'New password has been saved successfully. Please login again.',
        {
          position: toast.POSITION.TOP_CENTER
        }
      );
      dispatch(resetReducerData());
    }
  } catch (error) {
    validationErrorHandler(error, dispatch);
  }
};

export const inviteUserResetPassword = (newPassword, params, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: authTypes.GET_USER_PROFILE_REQUEST });

    const requestObject = {
      param: params.param,
      org_id: params.org_id,
      password1: newPassword.passwordNew1,
      password2: newPassword.passwordNew2
    };
    const response = await API.put(`/invite-user/`, requestObject);
    if (response.status === 200) {
      history.push('/login');
      toast.success(
        'New password has been saved successfully. Please login again.',
        {
          position: toast.POSITION.TOP_CENTER
        }
      );
      dispatch(resetReducerData());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const logoutUser = (history, intentional = false) => async (dispatch) => {
  try {
    await API.post('/logout/');
    localStorage.removeItem('token');
    Cookies.remove('token')
    localStorage.removeItem('role');
    dispatch(resetReducerData());
    dispatch({ type: authTypes.LOGOUT });
    history.push('/login');
    if (intentional) {
      localStorage.removeItem('wasSessionExpired');
      localStorage.removeItem('location');
    }
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // localStorage.removeItem('token');
      // localStorage.removeItem('role');
      dispatch({ type: authTypes.LOGOUT });
      // history.push('/login');
    }
  }
};

export const authLogout = (history) => async (dispatch) => {
  await dispatch(logoutUser(history));
  sessionStorage.clear();
  localStorage.clear();
  Cookies.remove("token");
  localStorage.setItem("tour", 1);
  dispatch(resetReducerData());
  dispatch({ type: authTypes.LOGOUT });
  window.location.assign(
    `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}/login&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`
  );
};

export const getAuthUser = (history, accessToken) => async (dispatch) => {
  try {
    dispatch({ type: authTypes.GET_USER_PROFILE_REQUEST });
    const { data } = await API.get('/auth-profile/');
    localStorage.setItem('role', data.user_metadata.role);
    localStorage.setItem('profile_pic', data.picture);
    const userProfile = {
      name: data.name,
      email: data.email,
      role: data.user_metadata.role,
      id: data.user_metadata.org_id ? data.user_metadata.org_id : 1,
      organisation: data.user_metadata.org_website,
      asset_groups: data.asset_groups || []
    };
    dispatch({
      type: authTypes.GET_USER_PROFILE_SUCCESS,
      payload: userProfile
    });
    if (data.user_metadata.role === 'PENDING') {
      dispatch(handleSignup(accessToken, history));
    }
    if (data.user_metadata.role) {
      dispatch(loginWithRedirect(data, history, accessToken));
    }
    dispatch({
      type: authTypes.GET_USER_PROFILE_SUCCESS,
      payload: userProfile
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getUser = (history, accessToken, page) => async (dispatch) => {
  try {
    dispatch({ type: authTypes.GET_USER_PROFILE_REQUEST });
    const { data } = await API.get('/user/profile/');
    localStorage.setItem('role', data.role);
    if (data.role === 'USER' || data.role === 'SUBSIDIARY' || data.role === 'VENDOR') {
      if (data.asset_groups.length) {
        localStorage.setItem('AssetGroupParams', JSON.stringify([{ name: 'asset_group', value: `${data.asset_groups[0].asset_group_id}` }]))
        localStorage.setItem('selectedGroup', JSON.stringify({ name: data.asset_groups[0].asset_group, value: `${data.asset_groups[0].asset_group_id}` }))
      }
    }
    else if (data.role === 'ADMIN' || data.role === 'MANAGER') {
      if (data.asset_groups.length) {
        const asset = data.asset_groups.find(el => el.asset_group === 'default')
        if (localStorage.getItem('AssetGroupParams') === null) {
          localStorage.setItem('AssetGroupParams', JSON.stringify([{ name: 'asset_group', value: `${asset.asset_group_id}` }]))
          localStorage.setItem('selectedGroup', JSON.stringify({ name: asset.asset_group, value: `${asset.asset_group_id}` }))
        }
      }
    }

    const date = new Date();

    date.setHours(date.getHours() + 6);
    localStorage.setItem('login_time', date)
    // const data = JSON.parse(localStorage.getItem('userProfile'));
    const userProfile = {
      name: data.full_name,
      email: data.email,
      role: data.role,
      id: data.id,
      mfa_status: data.mfa_status,
      organisation: data.org_name,
      scope: data.scope ? data.scope : '',
      avatar_color: data.avatar_color ? data.avatar_color : '',
      asset_groups: data.asset_groups.map(el => {
        return {
          id: el.asset_group_id,
          group_name: el.asset_group
        }
      }) || []
    };


    dispatch({
      type: authTypes.GET_USER_PROFILE_SUCCESS,
      payload: userProfile
    });
    dispatch({
      type: 'AVATAR_COLOR_SUCCESS',
      payload: userProfile.avatar_color
    });

    if (page !== 'dashboard') {
      if (data.role === 'PENDING') {
        await dispatch(handleSignup(accessToken, history, data));
      }

      if (data.role !== 'PENDING') {
        if (accessToken) {
          setTimeout(() => {
            dispatch(loginWithRedirect(data, history, accessToken));
          }, 1000);
        }
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history, true);
  }
};

export const updateAvatarColor = (history, color) => async (dispatch) => {
  try {
    dispatch({ type: 'UPDATE_AVATAR_COLOR_REQUEST' });
    const response = await API.put('/user/profile/', { avatar_color: color });

    if (response.status === 200) {
      dispatch({
        type: 'UPDATE_AVATAR_COLOR_SUCCESS',
        payload: response.data.avatar_color
      });
      localStorage.setItem('userProfile', JSON.stringify(response.data));
    }
  } catch (error) {
    errorHandler(error, dispatch, history, true);
  }
};

export const getUserProfileAfterSignup = (history, accessToken) => async (
  dispatch
) => {
  try {
    dispatch({ type: authTypes.GET_USER_PROFILE_REQUEST });
    const { data } = await API.get('/user/profile/');
    if (data.role) {
      localStorage.setItem('role', data.role);
      const userProfile = {
        name: data.full_name,
        email: data.email,
        role: data.role,
        id: data.id,
        mfa_status: data.mfa_status,
        organisation: data.org_name,
        scope: data.scope ? data.scope : '',
        avatar_color: data.avatar_color ? data.avatar_color : '',
        asset_groups: data.asset_groups.map(el => {
          return {
            id: el.asset_group_id,
            group_name: el.asset_group
          }
        }) || []
      };
      dispatch({
        type: authTypes.GET_USER_PROFILE_SUCCESS,
        payload: userProfile
      });
      if (data.role === 'USER' || data.role === 'SUBSIDIARY' || data.role === 'VENDOR') {
        if (data.asset_groups.length) {
          localStorage.setItem('AssetGroupParams', JSON.stringify([{ name: 'asset_group', value: `${data[0].asset_group_id}` }]))
          localStorage.setItem('selectedGroup', JSON.stringify({ name: data.asset_groups[0].asset_group, value: `${data.asset_groups[0].asset_group_id}` }))
        }
      }
      // localStorage.removeItem('tour');
      dispatch(loginWithRedirect(data, history, accessToken));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateProfile = (userName) => async (dispatch) => {
  try {
    const response = await API.put('/user/profile/', { full_name: userName });

    if (response.status === 200) {
      dispatch({
        type: authTypes.UPDATE_PROFILE_NAME,
        userName
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const toggleMFA = (params, history) => async (dispatch) => {
  try {
    const { data } = await API.post('/toggle-mfa/', params);
    dispatch({
      type: 'SUCCESS_MESSAGE_MODAL',
      message: {
        title: 'Success!',
        message: data.message,
        redirectTo: '',
        buttonTitle: 'Ok'
      }
    });
    dispatch(showSuccessModal());
    setTimeout(() => {
      localStorage.clear();
      Cookies.remove("token");
      window.location.assign(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}/login&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`
      );
    }, 500);
    localStorage.setItem('location', '/');
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const login = (user, history) => async (dispatch) => {
  try {
    dispatch({ type: authTypes.LOGIN_REQUEST });
    const config = {
      headers: {
        authorization: ''
      }
    };
    const { data } = await API.post('/login/', user, config);
    const userRoleStatus = data.state;
    Cookies.set("token", data.token, { expires: 2 });
    // localStorage.setItem('token', data.token);
    localStorage.setItem('role', userRoleStatus);

    const location = JSON.parse(localStorage.getItem('location'));
    const wasSessionExpired = JSON.parse(
      localStorage.getItem('wasSessionExpired')
    );

    const userInfo = data.user;
    const userProfile = {
      name: userInfo.name,
      email: userInfo.email,
      role: userInfo.role,
      id: userInfo.id
    };
    dispatch({
      type: authTypes.LOGIN_SUCCESS,
      payload: data,
      user: userProfile
    });

    if (userInfo.role === 'VERIFIED') {
      history.push({
        pathname: '/user/admin-confirmation'
      });
    } else if (userInfo.role === 'PENDING') {
      history.push({
        pathname: '/user/email-verification'
      });
    } else if (userInfo.role === 'ORG_PENDING') {
      history.push({
        pathname: '/user/org-verification'
      });
    } else if (userRoleStatus.startsWith('rh')) {
      if (wasSessionExpired) {
        if (location === '/') {
          history.push('/admin/orgs');
        } else {
          history.push(location);
        }
        localStorage.removeItem('wasSessionExpired');
        localStorage.removeItem('location');
      } else {
        history.push('/admin/orgs');
      }
    } else {
      if (wasSessionExpired) {
        history.push(location);
        localStorage.removeItem('wasSessionExpired');
        localStorage.removeItem('location');
      } else {
        history.push('/');
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history, true);
  }
};

export const handleSignup = (accessToken, history, data) => async (
  dispatch
) => {
  try {
    if (accessToken) {
      Cookies.set("token", accessToken, { expires: 2 });
      // localStorage.setItem('token', accessToken);
    }
    localStorage.setItem('userProfile', JSON.stringify(data));
    const response = await API.get('/post-signup/');
    if (response.status) {
      localStorage.removeItem('tour')
      dispatch(getUserProfileAfterSignup(history, accessToken));
    }
  } catch (error) {
    const userProfile = {
      name: null,
      email: null,
      role: null,
      id: null
    };
    dispatch({
      type: authTypes.LOGIN_SUCCESS,
      payload: data,
      user: userProfile
    });
    history.push('/login');
    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse: error.response.data.error
          ? error.response.data.error.message
            ? error.response.data.error.message
            : error.response.data.error
          : error.response.data.error.message,
        errorMessage: error.response.data.error
          ? error.response.data.error.message
            ? error.response.data.error.message
            : error.response.data.error
          : error.response.data.error.message,
        errorKeys: [],
        errorCode: error.status ? error.status : error.response.status
      }
    });
    dispatch(showFailureModal());
    setTimeout(() => {
      dispatch(authLogout())
    }, 3500);
  }
};

export const fetchSetupStaus = (history, location) => async (dispatch) => {
  try {
    dispatch({ type: 'ONBOARDING_STATUS_REQUEST' });
    dispatch(getSubscriptionStatus(history));
    const { data } = await API.get('/orgs/header/');
    if (data) {
      localStorage.setItem('dashboardStatus', JSON.stringify(data));
      dispatch({
        type: 'DASHBOARD_STATUS_SUCCESS',
        status: data.status,
        is_unvalidated: data.is_unvalidated,
        killed_by: data.killed_by,
        killed_ts: data.killed_ts,
        resumable: data.resumable,
        is_killed_scan: data.is_killed_scan,
        org_subscription: data.org_subscription,
        asm_score: data.asm_score,
        setup_finished: data.setup_finished
      });
      if (data.setup_finished === false) {
        history.push({
          pathname: '/user/login-form'
        });
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getSubscriptionStatus = (history) => async (
  dispatch
) => {
  try {
    dispatch({ type: "GET_MODIFY_PAYMENT_URL" });

    const response = await API_Payment.post(
      `/subscription-status/`,
      {}
    );

    if (response.status === 200) {
      localStorage.setItem('subscriptionStatus', JSON.stringify(response.data.status[0]))
      dispatch({
        type: "GET_SUBSCRIPTION_STATUS_SUCCESS",
        data: response.data.status[0],
      });
      if (response.data.status[0].status === 'inactive' || response.data.status[0].status === 'expired') {
        setTimeout(() => {
          dispatch(showSubscriptionWarningModal())
        }, 2000);
      }
    }
  } catch (error) {
    dispatch({
      type: "GET_SUBSCRIPTION_STATUS_SUCCESS",
      data: { 'status': 'active', 'product': 'enterprise' },
    });
    localStorage.setItem('subscriptionStatus', "{ 'status': 'active', 'product': 'enterprise' }")
    errorHandler(error, dispatch, history);
  }
};

export const loginWithRedirect = (userInfo, history, token) => async (
  dispatch
) => {
  try {
    const userRoleStatus = userInfo.role;
    let location = localStorage.getItem('location')
      ? JSON.parse(localStorage.getItem('location'))
      : '/';
    // const location = "/"
    const wasSessionExpired = false;
    const userProfile = {
      name: userInfo.full_name,
      email: userInfo.email,
      role: userInfo.role,
      id: userInfo.id,
      mfa_status: userInfo.mfa_status,
      organisation: userInfo.org_name,
      scope: userInfo.scope ? userInfo.scope : ''
    };
    if (token) {
      Cookies.set("token", token, { expires: 2 });
      // localStorage.setItem('token', token);
    }
    localStorage.setItem('userProfile', JSON.stringify(userInfo));
    dispatch({
      type: authTypes.LOGIN_SUCCESS,
      payload: userInfo,
      user: userProfile
    });
    if (userInfo.role === 'VERIFIED') {
      history.push({
        pathname: '/user/admin-confirmation'
      });
    } else if (userInfo.role === 'PENDING') {
      history.push({
        pathname: '/user/email-verification'
      });
    } else if (userInfo.role === 'MANAGER') {
      history.push({
        pathname: '/'
      });
    } else if (userInfo.role === 'ORG_PENDING') {
      history.push({
        pathname: '/user/org-verification'
      });
    } else if (userRoleStatus.startsWith('rh')) {
      if (location === '/') {
        history.push('/admin/orgs');
      } else {
        history.push(location);
      }
      localStorage.removeItem('wasSessionExpired');
      if (location === '/') {
        history.push('/admin/orgs');
      } else {
        history.push(location);
      }
    } else if (
      location &&
      location !== '/' &&
      !userRoleStatus.startsWith('rh') &&
      (userRoleStatus === 'ADMIN' || userRoleStatus === 'MANAGER') &&
      token
    ) {
      // dispatch(fetchSetupStaus(history, location));
      // dispatch(downloadReport(history));
    } else {
      // if (userRoleStatus === 'ADMIN') {
      //   dispatch(downloadReport(history));
      // }
      if (wasSessionExpired) {
        history.push(location);
        localStorage.removeItem('wasSessionExpired');
      } else {
        dispatch(fetchSetupStaus(history, location));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history, true);
  }
};

export const register = (user, history) => async (dispatch) => {
  try {
    dispatch({ type: authTypes.REGISTER_REQUEST });
    const { data } = await API.post('signup/', user);
    const userRoleStatus = data.user.role;
    Cookies.set("token", data.token, { expires: 2 });
    // localStorage.setItem('token', data.token);
    localStorage.setItem('role', userRoleStatus);

    dispatch({ type: authTypes.REGISTER_SUCCESS, payload: data });

    if (userRoleStatus === 'PENDING') {
      history.push({
        pathname: '/user/email-verification',
        state: { userState: userRoleStatus }
      });
    } else {
      history.push('/');
    }
  } catch (error) {
    validationErrorHandler(error, dispatch);
  }
};

export const handleSendOtp = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'SEND_OTP_REQUEST' });
    const response = await API.get('user/otp-verification/');
    if (response.status === 200) {
      dispatch({ type: 'SEND_OTP_SUCCESS' });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleAccountVerificationCheck = (history, otp) => async (
  dispatch
) => {
  dispatch(hideFailureModal());
  try {
    const requestBody = {
      otp
    };
    const response = await API.post('user/otp-verification/', requestBody);

    if (response.status === 200) {
      localStorage.setItem('role', response.data.role);
      history.push({
        pathname: '/user/org-verification'
      });
      dispatch(setCurrentUser());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const resendVerificationMail = (history) => async (dispatch) => {
  try {
    const response = await API.get('/user/resend-verification-mail/');

    if (response.status === 200) {
      toast.success('OTP sent again', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleRecaptchVerification = (
  token,
  user,
  from,
  history
) => async (dispatch) => {
  try {
    const requestBody = {
      response_token: token
    };
    const response = await API.post('/recaptcha/verify/', requestBody);
    if (response.status === 200) {
      from === 'SIGNIN'
        ? dispatch(login(user, history))
        : dispatch(register(user, history));
    }
  } catch (error) {
    if (error.response.status === 401) {
      const errorMsg = 'Could not verify you as a human.';
      dispatch({
        type: errorTypes.CATCH_ERROR,
        errorInfo: {
          errorResponse: errorMsg,
          errorMessage: errorMsg,
          errorKeys: [],
          errorCode: 401
        }
      });
    }
  }
};

export const emailVerification = (email, history) => async (dispatch) => {
  try {
    const response = await API.post('/orgs/email/', email);
    if (response.status === 201 || 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: response.data.message,
          buttonTitle: 'Ok'
        }
      });
      dispatch(fetchTargets());
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteEmail = (email, history) => async (dispatch) => {
  try {
    const response = await API.delete('/orgs/email/', {
      data: {
        email
      }
    });
    if (response.status === 201 || 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: `Email removed from mailing list.`,
          buttonTitle: 'Ok'
        }
      });
      dispatch(fetchTargets());
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const slackVerification = (code, history, path) => async (dispatch) => {
  try {
    const data = {
      code,
      path
    };
    const response = await API.post('/orgs/slack-verification/', data);
    if (response.status === 201 || 200) {
      localStorage.removeItem('slackCode');
      localStorage.removeItem('pagerdutyCode');
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: response.data.message,
          redirectTo: `/${path}`,
          buttonTitle: 'Ok'
        }
      });
      dispatch(fetchTargets());
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
    localStorage.removeItem('slackCode');
    localStorage.removeItem('pagerdutyCode');
  }
};
export const pagerdutyIntegration = (code, history) => async (dispatch) => {
  try {
    const response = await API.post('/orgs/pagerduty/', code);
    if (response.status === 201 || response.status === 200) {
      localStorage.removeItem('slackCode');
      localStorage.removeItem('pagerdutyCode');
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: response.data.message,
          redirectTo: '/settings/integrations',
          buttonTitle: 'Ok'
        }
      });
      dispatch(fetchTargets());
      dispatch(showSuccessModal());
    }
  } catch (error) {
    history.push('/settings/integrations');
    errorHandler(error, dispatch, history);
    localStorage.removeItem('slackCode');
    localStorage.removeItem('pagerdutyCode');
  }
};
export const deletePagerDuty = (history) => async (dispatch) => {
  try {
    const response = await API.delete('/orgs/pagerduty/');
    if (response.status === 204 || response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Deleted successfully',
          redirectTo: '',
          buttonTitle: 'Ok',
          showCancelButton: false,
          showJiraStatus: false
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchTargets());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDarkThemeChanges = () => async (dispatch) => {
  dispatch({
    type: 'CHANGE_THEME_TO_DARK'
  });
};

export const handleLightThemeChanges = () => async (dispatch) => {
  dispatch({
    type: 'CHANGE_THEME_TO_LIGHT'
  });
};

export const fetchReportTab = (history) => async (dispatch) => {
  try {
    const { data } = await API.get('/orgs/access-control/');
    if (data) {
      dispatch({
        type: 'REPORT_TAB_SUCCESS',
        REPORTS_SUB_SECTIONS: data.REPORTS_SUB_SECTIONS,
        REPORTS_TOP_LEVEL: data.REPORTS_TOP_LEVEL,
      });
      if (data.setup_finished === false) {
        history.push({
          pathname: '/user/login-form'
        });
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
