import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from 'utils/loader';
import { fetchNewFeedData, fetchNextFeedData, seenRssFeed } from 'actions/dashboard';
import { withRouter } from 'react-router';
import NoDataFound from 'components/no_data_found';
import moment from "moment-timezone";
import InfiniteScroll from 'react-infinite-scroller';
import { groupBySkeletonCard } from 'components/skeleton_loader';
import clsx from "clsx";
import newIcon from 'assets/sparkler.png';
import { CustomTooltip } from 'components/tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const useStyles = makeStyles(() => ({
    typography: {
        padding: 10,
    },
    drawer: {
        width: 460,
        background: 'var(--color-bodyBG)'
    },
    dataList: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    container: {
        // border: '2px solid var(--color-running)',
        padding: '3px 20px',
        borderRadius: 67,
        border: '1px solid #BABABA',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 500,
        marginLeft: 'auto',
        marginRight: '20px',
        cursor: 'pointer'
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        margin: '6px 0',
        fontSize: 12,
        fontWeight: 400,
        color: '#5f6368',
        lineHeight: '11px'
    },
    title: {
        fontSize: 14
    },
    textContent: {
        padding: '0 20px 20px',

    },

    card: {
        flex: 1,
        // backgroundColor: 'var(--color-lighter-grey)',
        borderRadius: 4,
        margin: '12px',
        border: '1px solid var(--color-stroke)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            boxShadow: 'var(--light-mode-box-shadow)'
        }
    },
    CloseIcon: {
        position: 'absolute',
        right: 15,
        top: 82,
        cursor: 'pointer',
        fontSize: 20
    }

}));

const WhatIsNew = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [unreadCount, setUnreadCount] = React.useState(0);
    const rssFeed = useSelector((state) => state.dashboard.rssFeed);
    const [value, setValue] = React.useState('product-updates');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(fetchNewFeedData(newValue, props.history));
    };
    useEffect(() => {
        dispatch(fetchNewFeedData('product-updates', props.history));
    }, [])
    useEffect(() => {
        if (rssFeed.length) {
            const unread = rssFeed.filter(el => el.is_seen === false).map(el => el.id)
            if (unread.length) {
                setUnreadCount(unread.length)
            } else {
                setUnreadCount(0)
            }
        }
    }, [rssFeed])
    const nextItems = useSelector((state) => state.dashboard.nextItems);
    const Count = useSelector((state) => state.dashboard.Count);
    const cardLoading = useSelector((state) => state.dashboard.cardLoading);
    const handleClick = (event) => {
        setOpen(true);

        dispatch(fetchNewFeedData(value, props.history));
    };
    const handleRead = (id, is_seen) => {
        // const unread = rssFeed.filter(el => el.is_seen === false).map(el => el.id)
        if (is_seen === false) {
            dispatch(seenRssFeed([id], props.history))
        }
    };
    const handleClose = async () => {
        await dispatch(fetchNewFeedData(value, props.history));
        if (rssFeed.length) {
            const unread = rssFeed.filter(el => el.is_seen === false).map(el => el.id)
            if (unread.length) {
                setUnreadCount(unread.length)
            } else {
                setUnreadCount(0)
            }
        }
        setOpen(false);
    };
    const dispatchNextDataFetch = () =>
        dispatch(fetchNextFeedData());
    return (
        <div className='asset'>
            <div >
                <CustomTooltip title={`What's new`}>
                    <div className={classes.container} style={{ marginRight: 20, cursor: 'pointer' }} onClick={handleClick}>
                        <Badge
                            // color="primary"
                            // overlap="rectangular"
                            variant={unreadCount > 0 ? 'dot' : 'standard'}
                        >
                            <img
                                src={newIcon}
                                alt="notifications"

                            />
                        </Badge>
                    </div>
                </CustomTooltip>
                <>
                    <Drawer className='drawer' anchor='right' open={open} onClose={() => handleClose(false)}>
                        <div className={classes.drawer}>
                            <Paper square>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleChange}
                                    aria-label="disabled tabs example"
                                >
                                    <Tab label="Product Features" value="product-updates" />
                                    <Tab label="Release Notes" value="release-notes" style={{ marginLeft: 10 }} />
                                </Tabs>
                            </Paper>
                            <CloseIcon title="Close" className={classes.CloseIcon} onClick={() => handleClose(false)} />
                            <div className={classes.dataList} >
                                <div className="dashboard_table_title_div">
                                    <p className="dashboard_table_title">
                                        {unreadCount === 0 ? "You're all caught up." : `Explore ${unreadCount} new features`}
                                    </p>
                                </div>
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={dispatchNextDataFetch}
                                    hasMore={Boolean(nextItems)}
                                    loader={
                                        <div className="loader" key={0}>
                                            {groupBySkeletonCard()}
                                        </div>
                                    }
                                    useWindow={false}>
                                    {cardLoading ? <Loading
                                        type="Triangle"
                                        height="50"
                                        width="50"
                                        loaderStyle={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "90vh",
                                        }}
                                    /> : !cardLoading && Count === 0 ?
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "90vh",
                                        }}>

                                            <NoDataFound style={{ margin: "0rem 2rem" }} />
                                        </div> :
                                        <div
                                            style={{
                                                height: '93vh',
                                                overflow: 'auto',
                                                marginRight: 11
                                            }}
                                            className='scroll'>
                                            <div>
                                                {rssFeed.map((item) =>
                                                    <div key={item.id} className={classes.card} style={{ background: "#fff" }} onClick={() => handleRead(item.id, item.is_seen)}>
                                                        <a href={item.link} target='_blank'>
                                                            <div style={{ display: 'flex', padding: '20px 20px 0' }}>
                                                                <img src={item.image_url} alt="" width={100} height={80} />
                                                                <div style={{ marginLeft: 12 }}>
                                                                    <CustomTooltip title={item.title}>
                                                                        <h5 className={clsx(classes.title, 'title_elipsis')}><a href={item.link} target='_blank'>{item.title}</a></h5>
                                                                    </CustomTooltip>
                                                                    <span className={classes.date}> <CalendarTodayIcon style={{ fontSize: 14, marginRight: 6 }} />{moment.utc(item.published_date).format('ll')}</span>
                                                                    <div
                                                                        className="ellipsis"
                                                                        style={{
                                                                            borderRadius: 28,
                                                                            background: '#f0f8fe',
                                                                            border: `1.3px solid #f0f8fe`,
                                                                            padding: '0px 10px',
                                                                            color: '#275ecc',
                                                                            width: 'fit-content',
                                                                            fontSize: 12,
                                                                            fontWeight: 500,
                                                                            height: "fit-content",
                                                                            margin: "0 10px 10px 0",
                                                                            cursor: "pointer",
                                                                        }}

                                                                    >
                                                                        {item.feed}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className={classes.textContent}>

                                                                <div className='summary summary_elipsis' dangerouslySetInnerHTML={{ __html: item.summary }}>
                                                                    {/* {item.summary} */}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                    }
                                </InfiniteScroll>
                            </div>


                        </div>
                    </Drawer>

                </>
            </div>

        </div >
    );
};

export default React.memo(withRouter(WhatIsNew));
