import { CATCH_ERROR } from "action_types/error";
// import metadata_modal from 'components/material_components/material_modals/metadata_modal';

const initialState = {
  data: [],
  httpGroup: [],
  assets: [],
  tags: [],
  ports: [],
  expTags: [],
  correlationScores: [],
  loading: false,
  cardLoader: false,
  dropdownLoader: false,
  cardLoading: false,
  nextItems: null,
  previousItems: null,
  assetNextItems: null,
  assetPreviousItems: null,
  assetsCount: null,
  Count: null,
  assetTypes: [],
  assetTypesWeAccept: [],
  scansAssets: [],
  loadingUnique: false,
  assetTypesUnique: [],
  assetsRequestId: undefined,
  tagTypes: [],
  cardsData: null,
  assetCardsData: {
    asset_count: 0,
    last_change_days_delta: "",
    missing_asset_count: 0,
    missing_asset_count_percent_change: 0,
    new_asset_count: 0,
    new_asset_count_percent_change: 0,
    active_asset_count: 0,
    active_asset_count_percent_change: 0,
  },
  currentUrl: "",
  associatedIssues: "",
  possibleAssetExposure: [],
  thirdPartyAssetType: [],
  technologies: [],
  notificationsData: {},
  notificationsFilters: [],
  srAssetsFilter: [],
  assetGroup: [],
  assetGroupList: [],
  assetUserGroupList: [],
  assetUserGroupsList: [],
  technologiesList: [],
  targetList: [],
  mindMap: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "RESET_REDUCER_DATA":
      return {
        ...state,
        ...initialState,
      };
    case "RESET_ASSET_DATA":
      return {
        ...state,
        nextItems: null,
        previousItems: null,
        assetNextItems: null,
        assetPreviousItems: null,
        assetsCount: null,
        Count: null,
        data: [],
        loading: true,
        assets: [],
      };
    case "UPDATE_SAVED_ASSETS":
      return {
        ...state,
        data: action.assets,
        loading: false,
      };

    case "UPDATE_TAGS_REQUEST":
    case "FETCH_SCAN_DETAILS_ASSETS_REQUEST":
    case "FETCH_MINDMAP_REQUEST":
      return {
        ...state,
        loading: true,
        mindMap: [],
      };
    case "FETCH_DROPDOWN_ASSETS_REQUEST":
      return {
        ...state,
        dropdownLoader: true,
      };
    case "FETCH_ASSET_REQUEST":
      return {
        ...state,
        loading: true,
        assetsRequestId: action.id,
      };
    case "FETCH_ASSET_HTTP_GROUP_REQUEST":
      return {
        ...state,
        loadingUnique: true,
        // assetsRequestId: action.id,
      };
    case "FILTER_UNIQUE_ASSET_REQUEST":
      return {
        ...state,
        loadingUnique: true,
        assetsRequestId: action.id,
      };
    case "FETCH_NEXT_ATTACK_SURFACE":
      return {
        ...state,
        assetsRequestId: action.id,
      };
    case "FETCH_ASSET_SUCCESS":
      if (action.id === state.assetsRequestId) {
        return {
          ...state,
          data: [...action.assets],
          assetsCount: action.assetsCount,
          nextItems: action.nextItems,
          previousItems: action.previousItems,
          loading: false,
          loadingUnique: false,
          currentUrl: action.currentUrl,
        };
      }
      return state;
    case "FETCH_HTTP_ASSET_SUCCESS":
      return {
        ...state,
        httpGroup: [...action.assets],
        assetsCount: action.assetsCount,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        loading: false,
        loadingUnique: false,
        currentUrl: action.currentUrl,
      };
    case "FETCH_MINDMAP_SUCCESS":
      return {
        ...state,
        loading: false,
        mindMap: action.data,

      };
    case "FETCH_ASSET_HTTP_GROUP_SUCCESS":
      return {
        ...state,
        assets: [...action.assets],
        Count: action.assetsCount,
        assetNextItems: action.nextItems,
        assetPreviousItems: action.previousItems,
        loading: false,
        loadingUnique: false,
        currentUrl: action.currentUrl,
      };


    case "NOTIFICATIONS_RECORDS_REQUEST":
      return { ...state, loading: true };

    case "NOTIFICATIONS_RECORDS_SUCCESS":
      return {
        ...state,
        notificationsData: action.data,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        notificationsFilters:
          action.meta.type === "new_issue_count"
            ? action.meta.titles
            : action.meta.type === "new_asset_count"
              ? action.meta.asset_types
              : action.meta.signatures,
        srAssetsFilter: action.meta.type === "new_issue_count" ? action.meta.assets : [],
        loading: false,
      };

    case "FETCH_ASSET_EXP_TAGS_SUCCESS":
      return {
        ...state,
        expTags: action.tags,
      };
    case "FETCH_ASSET_EXP_CORRELATION_SCORES_SUCCESS":
      return {
        ...state,
        correlationScores: action.correlationScores,
      };

    case "FETCH_SCAN_DETAILS_ASSETS_SUCCESS":
      return {
        ...state,
        data: [...action.assets],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        assetsCount: action.assetsCount,
        scanType: action.scanType,
        loading: false,
      };
    case "FETCH_ASSET_UNIQUE_TYPES":
    case "FETCH_ORGS_ASSET_UNIQUE_TYPES":
      return {
        ...state,
        assetTypes: [...action.data],
        tags: action.tags,
        ports: action.ports,
        dropdownLoader: false,
      };

    case "FETCH_ALL_ASSET_UNIQUE_TYPES":
      return {
        ...state,
        assetTypesWeAccept: [...action.data],
      };
    case "FETCH_ASSET_LIST_ON_SEARCH_SUCCESS":
      return {
        ...state,
        scansAssets: [...action.assets],
      };
    case CATCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    case "FILTER_CSV_SUCCESS":
      return {
        ...state,
        loading: false,
      };
    case "FETCH_ASSET_CARD_NUMBER_SUCCESS":
      return {
        ...state,
        cardsData: action.data,
      };
    case "FETCH_ASSET_EXPOSURES_CARD_NUMBER_REQUEST":
      return {
        ...state,
        cardLoading: true,
      };
    case "FETCH_ASSET_EXPOSURES_CARD_NUMBER_SUCCESS":
      return {
        ...state,
        assetCardsData: {
          asset_count: action.data.asset_count,
          last_change_days_delta: action.data.last_change_days_delta,
          missing_asset_count: action.data.missing_asset_count,
          missing_asset_count_percent_change:
            action.data.missing_asset_count_percent_change,
          new_asset_count: action.data.new_asset_count,
          new_asset_count_percent_change:
            action.data.new_asset_count_percent_change,
          active_asset_count: action.data.active_asset_count || 0,
          active_asset_count_percent_change:
            action.data.active_asset_count_percent_change || 0,
        },
        assetTypesUnique: action.assetTypes,
        tagTypes: action.tagTypes,
        cardLoading: false,
      };
    case "SHOW_RELATED_ISSUES":
      return {
        ...state,
        associatedIssues: action.issues,
        loading: false,
      };
    case "FETCH_POSSIBLE_ASSET_SUCCESS":
      return {
        ...state,
        possibleAssetExposure: action.assetsData,
        loading: false,
        nextItems: action.assetsData.next,
        previousItems: action.assetsData.previous,
      };
    case "FETCH_THIRD_PARTY_ASSET_TYPE_SUCCESS":
      return {
        ...state,
        thirdPartyAssetType: action.data,
      };
    case "FETCH_TECHNOLOGIES_SUCCESS":
      return {
        ...state,
        technologies: action.techsData,
        loading: false,
      };
    case "FETCH_TECHNOLOGIES_LIST_SUCCESS":
      return {
        ...state,
        technologiesList: action.data,
        loading: false,
      };
    case "SHOW_ASSET_EXPOSURE_CARD_LOADER":
      return {
        ...state,
        cardLoader: true,
      };
    case "FETCH_POSSIBLE_ASSET_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_TECHNOLOGIES_REQUEST":
    case "FETCH_ASSET_GROUP_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "HIDE_ASSET_EXPOSURE_CARD_LOADER":
      return {
        ...state,
        cardLoader: false,
      };
    case "FETCH_NEW_ASSET_TARGET_REQUEST":
      return {
        ...state,
        cardLoader: true,
      };
    case "FETCH_ASSET_GROUP_SUCCESS":
      return {
        ...state,
        loading: false,
        assetGroup: [...action.data],
        assetsCount: action.assetsCount,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        loading: false,
        loadingUnique: false,
        currentUrl: action.currentUrl,
      };
    case "FETCH_ASSET_TARGET_SUCCESS":
      return {
        ...state,
        targetList: [...action.data],
      };
    case "FETCH_NEW_ASSET_TARGET_SUCCESS":
      return {
        ...state,
        cardLoader: false,
      };
    case "FETCH_ALL_ASSET_GROUP_SUCCESS":
      return {
        ...state,
        loading: false,
        assetGroupList: [...action.data],
      };
    case "FETCH_ALL_USER_ASSET_GROUP_SUCCESS":
      return {
        ...state,
        loading: false,
        assetUserGroupList: [...action.data],
      };
    case "FETCH_ALL_USER_ASSET_GROUP_LIST_SUCCESS":
      return {
        ...state,
        // loading: false,
        assetUserGroupsList: [...action.data],
      };
    default:
      return state;
  }
}
